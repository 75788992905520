import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

const StyledBackgroundImage = styled(BackgroundImage)`
  width: 100%;
  height: 30vw;
  text-align: center;
`;

const BannerHeader = ({className, image, imagePositionY, imageProps, children}) => {
  if (!image) {
    return null
  }
  const backgroundPosition = `0% ${typeof(imagePositionY) === 'number' ? imagePositionY : 50}%`;
  return (
    <StyledBackgroundImage
      Tag="section"
      className={className}
      fluid={image.childImageSharp.fluid}
      {...imageProps}
      style={{
        backgroundPosition,
      }}
    >
      {children}
    </StyledBackgroundImage>
  );
};

BannerHeader.propTypes = {
  className: PropTypes.string,
  imageProps: PropTypes.object,
  image: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.object
    })
  })
};

export default BannerHeader;