import React from 'react';
import { withBreakpoints } from 'gatsby-plugin-breakpoints';
import PropTypes from 'prop-types';
import LinkBox from "./LinkBox";
import { Section } from "../Markup";

const LinkSection = ({ playImage, gamesImage, artImage, breakpoints: { tablet, desktop } }) => {
  const item1 = <LinkBox image={playImage} path="/playable" title="Play Now" />;
  const item2 = <LinkBox image={gamesImage} path="/events" title="Events" />;
  const item3 = <LinkBox image={artImage} path="/art" title="Art" />;
  return (
    <Section className="pb-0">
      {tablet && !desktop ?
        (<div>
          <div className="columns">
            <div className="column pb-0 is-6 is-offset-3">{item1}</div>
          </div>
          <div className="columns mb-0">
            <div className="column pb-0">{item2}</div>
            <div className="column pb-0">{item3}</div>
          </div>
        </div>):
        (<div className="columns mb-0">
          <div className="column pb-0">{item1}</div>
          <div className="column pb-0">{item2}</div>
          <div className="column pb-0">{item3}</div>
        </div>)
      }
    </Section>
  );
};

const imageProps = PropTypes.shape({
  childImageSharp: PropTypes.shape({
    fluid: PropTypes.object
  })
});

LinkSection.propTypes = {
  playImage: imageProps,
  gamesImage: imageProps,
  artImage: imageProps,
  breakpoints: PropTypes.shape({
    mobile: PropTypes.bool,
    tablet: PropTypes.bool,
    desktop: PropTypes.bool,
    widescreen: PropTypes.bool,
    fullhd: PropTypes.bool
  })
};


export default withBreakpoints(LinkSection);