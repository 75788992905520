import React from "react";
import PropTypes from "prop-types";
import styled from 'styled-components'
import { Link as GatsbyLink } from "gatsby";
import Img from "gatsby-image";

const Link = styled(GatsbyLink)`
  font-size: 0;
  line-height: 1;
  & > div {
    opacity: 0.5;
    transition: opacity .5s;
  }
  &:hover, &:focus {
    opacity: 1;
    & > div {
      opacity: 1;
    }
  }
`;

const LinkBox = ({image, title, path}) => (
  <>
    <h2 className="title is-size-3 has-text-centered">{title}</h2>
    <Link to={path}>
      <Img  fadeIn={false} loading="eager" fluid={image.childImageSharp.fluid} alt={title}/>
    </Link>
  </>
);

LinkBox.propTypes = {
  image: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.object
    })
  }),
  link: PropTypes.string,
  title: PropTypes.string,
};

export default LinkBox;
