import React from "react";
import Img from "gatsby-image"
import PropTypes from "prop-types";
import styled from 'styled-components'
import { navigate } from "gatsby";

const StyledSvg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;


/**
 *
 * @param url - href or path. Path will handle by gatsby navigate func
 * @param shape - it's an svg shape for target image. Make sure shape correspond to image with proper width and height
 * @param target - target="_blank"
 * @returns {*}
 * @constructor
 */
const ClickableShape = ({url, shape, target}) => {

  const handleClick = (event) => {
    const isNavigateLink = url.startsWith('/');
    if (isNavigateLink) {
      event.preventDefault();
      navigate(url)
    }
  };

  return (
    <a xlinkHref={url} target={target} onClick={handleClick}>
      {shape}
    </a>
  )
};

const ClickableImage = (
  {
    bgImage: {childImageSharp: {fluid}} = {childImageSharp: {}},
    links = []
  }) => {
  if (!fluid || links.length === 0) {
    return null
  }

  const {srcSet = '', aspectRatio = 1} = fluid;
  const originalImageWidth = parseInt(srcSet.split(' ').pop() || 0);
  const originalImageHeight = parseInt(originalImageWidth / aspectRatio);
  const viewBox = `0 0 ${originalImageWidth} ${originalImageHeight}`;

  return (
    <div className="is-relative">
      <Img
        backgroundColor="#ffffff"
        fadeIn={false}
        fluid={fluid}
      />
      <StyledSvg
        width="100%"
        height="100%"
        viewBox={viewBox}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="HomeNavigationGroup"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Group">
            <g id="shapes">
              {links.map((linkProps, index) => (<ClickableShape {...linkProps} key={index}/>))}
            </g>
          </g>
        </g>
      </StyledSvg>
    </div>
  );
};

ClickableImage.propTypes = {
  instagramUrl: PropTypes.string,
  twitterUrl: PropTypes.string,
  bgImage: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.object
    })
  }),
  links: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string,
    shape: PropTypes.object,
  }))
};

export default ClickableImage;
