import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import BannerHeader from '../components/BannerHeader'
import ClickableImage from "../components/ClickableImage";
import Layout from "../components/Layout";
import LinkSection from '../components/Home/LinkSection'
import '../components/all.scss'

const IndexPage = (
  {
    data: {
      markdownRemark: {
        frontmatter: {
          footer_image: footerImage,
          header_image: headerImage,
          art_image: artImage,
          games_image: gamesImage,
          play_image: playImage,
          instagram_url: instagramUrl,
          twitter_url: twitterUrl
        }
      }
    }
  }
) => {

  const links = [
    {
      url: instagramUrl,
      shape: (<polygon id="instagram" fillOpacity="0" fill="#BD10E0"
                       points="2297.91253 449.447745 2379.30271 892.815825 1967.29251 980.081844 1922.47975 483.164162"></polygon>),
      target: '_blank'
    },
    {
      url: twitterUrl,
      shape: (<polygon id="twitter" fillOpacity="0" fill="#4A90E2"
                       points="672.041957 889.309268 315.812547 1197.37647 227.836183 1171.08351 41.0148045 899.909764 423.545531 575.491063"></polygon>),
      target: '_blank'
    },
    {
      url: '/contact',
      shape: (<polygon id="contact" fillOpacity="0" fill="#F8E71C"
                       points="1185.75413 960.926344 1185.75413 1491.60396 1559.95006 1491.60396 1600.46179 980.081844"></polygon>)
    },
    {
      url: '/bio',
      shape: (<polygon id="bio" fillOpacity="0" fill="#F5A623"
                       points="1172.16735 960.926344 1172.16735 1491.60396 821.987587 1491.60396 810.197044 960.926344"></polygon>)
    }
  ];

  return (
    <Layout footer={<ClickableImage bgImage={footerImage} links={links}/>} fullWidth>

      <BannerHeader image={headerImage} imgProps={{backgroundColor: "#c8cece"}}/>

      <LinkSection playImage={playImage} gamesImage={gamesImage} artImage={artImage}/>

    </Layout>
  );
};

const gatsbyImagePropTypes = PropTypes.shape({
  childImageSharp: PropTypes.shape({
    fluid: PropTypes.object
  })
});

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      html: PropTypes.string,
      frontmatter: PropTypes.shape({
        footer_image: gatsbyImagePropTypes,
        header_image: gatsbyImagePropTypes,
        art_image: gatsbyImagePropTypes,
        games_image: gatsbyImagePropTypes,
        play_image: gatsbyImagePropTypes,
        instagram_url: PropTypes.string,
        twitter_url: PropTypes.string
      })
    })
  })
};

export default IndexPage

export const pageQuery = graphql`
  query IndexPage {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        twitter_url
        instagram_url
        header_image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        footer_image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        art_image {
          childImageSharp {
            fluid(maxWidth: 650, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        games_image {
          childImageSharp {
            fluid(maxWidth: 650, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        play_image {
          childImageSharp {
            fluid(maxWidth: 650, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
